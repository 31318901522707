body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", R
  oboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, 
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
  font: black;
  font-weight: 400 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}