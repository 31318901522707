.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main {
  max-width: 98%;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  margin-top: 120px;
}

.main2 {
  max-width: 98%;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  margin-top: 160px;
}

h1 {
  font-weight: 400 !important;
}

p {
  font-weight: 400 !important;
}

.table tbody tr{
  color: #000 !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.space{
  margin-top: 40px;
}

.bottom-space{
  margin-bottom: 40px;
}

.bottom-space-ridotto{
  margin-bottom: 20px;
}

.containerorg {
	width:45%;
	max-width:90%;
	margin-right: auto;
	margin-left: auto;
}

.marginbutton {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.btn-navbar-1 {
  color: #fff !important;
}

.btn-size{
  width: 15rem;
}

.btn-add-user {
  float:right;
  margin-top:5px;
}

.confirm-box-content{
  z-index: 100;
}

/* Header*/
.header {
  bottom: 20px;
  width: 100%;
  height: 90px;
  position: fixed;
  top:0;
  z-index: 4;
}

.menu {
  color: white;
}

.navbar { background-color: rgb(16, 72, 163); }
.navbar-light .navbar-nav .nav-link  {
  text-align:center;
  color: #00040c;
  font-weight: 400 !important;
}

.navbar-brand {
  font-size: 1.4em;
  color: #00040c;
  left:0;
}

.navbar-toggler {
  background-color: rgb(228, 228, 228);
}

.nav-pills .nav-link.active {
  background-color: rgb(162, 205, 253) !important;
}

a:hover { color: rgb(7, 7, 6); }

.nav-link {
  border : solid 2px rgb(5, 15, 19);
  border-radius : 5px;
  background-color: white !important;
  padding: 5px;
  min-width: 100px;
  margin-left: 10px;
}

.nav-link:hover {
  background-color: rgb(162, 205, 253) !important;
}

.nav-item a{
  width: auto !important
}

.dropdown-item {
  text-align: left !important;
  margin-left: 8px !important;
  font-weight: 400 !important;
  width: auto;
}

.dropdown-item:hover {
  background-color: #77a0f8 !important;
}

.navbar .dropdown-menu-left {
  min-width: 13.1rem !important;
}

.navbar .dropdown-menu-left .dropdown-menu {
  right: 0 !important;
  left: auto !important;
  min-width: 12.5rem !important;
}

.navbar .dropdown-menu-left .dropdown-menu .submenu .dropdown-menu {
  position: absolute !important;
  right: 198px !important;
}

.navbar .submenu a{
  text-align: left !important;
}

.navbar .dropdown-menu {
  margin-left: 0.6rem !important;
}
.navbar .dropdown-menu a {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;

}

.myDropdown .dropdown-menu{
  min-width: 12.7rem !important;
  position: relative !important;
  margin-right: 100px;
}

.AreeDropdown {
  min-width: 9rem !important;
}

.AreeDropdown .dropdown-menu {
  min-width: 8.5rem !important;
}

.nav-link-home {
  color: rgb(7, 7, 6) !important;
}

.spinner {
  text-align: center;
  font-size: large;
  font-weight: bold;
  padding: 20%;
}

.orgNameFix {
  right: 30px;
  position: absolute;
  top:160px;
}

.filtri {
  margin-left: 2% !important;
}

.sb-filtri{
  margin-top: 40px !important;
  margin-left: 50px;
}

.addZone {
  width: '100%';
  text-align:right;
  justify-content: 'center';
  align-Items: 'center';
  position: fixed;
  right:10%;
  top: 50%;
}

.btnOrganizzazione {
  width: '100%';
  text-align:right;
  justify-content: 'center';
  align-Items: 'center';
  position: fixed;
  right:10%;
  top: 55%;
}

.btn-size-zona{
  width: 9rem !important;
}

.labelfile {
  margin-top: 10px;
  text-align: right;
  font-size: 25px;
}

.minwidth {
  min-width: 250px;
}

.alertclass {
  width: 100%;
  display : block;
  margin: 5px;
}

.allWidth {
  width: 100% !important;
}

.areaTitle {
  width: 100%;
}

.downloadcsv {
  margin: 5px;
  text-align: right;
}


.fixtitle {
  position: sticky;
  background-color: rgb(232, 238, 250);
  top: 110px;
  left: 0;
  z-index: 2;
}

.titleRow {
  font-size: 20px;
}

.titleRowFixed {
  font-size: 20px;
  position: sticky;
}

@media screen and (max-width: 900px) {
  .titleRow {
    margin-left: 15%
  }

  .addZone {
    width: 100%;
    margin-top: 15px;
    text-align:center;
    justify-content: 'center';
    align-Items: 'center';
    position: unset;
    right:unset;
    top: unset;
  }

  .btnOrganizzazione {
    width: 100%;
    margin-top: 15px;
    text-align: center;
    justify-content: 'center';
    align-Items: 'center';
    position: unset;
    right:unset;
    top: unset;
  }

  .btnMinWidth {
    width: 95%;
  }
}

.thFixed {
  background-color: rgb(232, 238, 250);
}

.titileZIndex {
  z-index: 0 !important;
}

.numeri730Label {
  font-weight: bold;
  margin: 15px;
}

.th {
  position: sticky;
  background-color: rgb(232, 238, 250);
  top: 170px; 
}

.divTabel {
  margin-bottom: 80px;
}

.aclibutton {
  color: transparent;
  padding: 5px 10px;
  border-radius: 4px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;

}

.aclieditbutton {
  font-size: 20px;
  color: #282c34;
}

.btnGenerale {
  font-weight: bold !important;
  font-size: 13px !important;
  background: #0a0aa8 !important;
  color: white !important;
}

.sottoitems {
  align-self: flex-start !important;
}

.intestazioni{
  text-align: center !important;
}

.number {
  text-align: right !important;
}

.titleFlex  {
  position: fixed !important;
  margin-left: calc(30%) !important;
}

.buttonDownloadFattura {
  margin-left: 94%;
}

.divDropdownbutton {
  left: 0px !important;
  position: sticky !important;
}

.tabella {
  padding: 15px;
}

.buttonDownloadCampagna {
  left: 90% !important;
  position: sticky !important;
}

.fixboth {
  position: sticky;
  background-color: rgb(232, 238, 250);
  top: 170px;
  left: 0;
  z-index: 2;
}

.thHorizontalSize {
  min-width: 350px;
}

.thHorizontalSize2 {
  min-width: 150px;
}

.fix {
  position: sticky;
  background-color: rgb(217, 228, 251);
  left: 0;
}

.tdSize {
  min-width: 120px;
}

.campagna730Btn {
  margin-left: -10px;
}

.allYearsCheckbox {
  font-size: 18px;
  font-weight: 400;
}

.scrollAllyear >div{
  position: sticky !important;
  left: 5px !important;
}

.allyear {
  margin-top: 45px;
}

.tableTurniLavoro th{
  font-size: 1rem !important;
  
}

.tableTurniLavoro td{
  font-size: 1rem !important;
  font-weight: 350 !important;
}

.tableOrganizzazione th{
  font-size: 1rem !important;
}

.tableOrganizzazione td{
  font-size: 1rem !important;
  font-weight: 350 !important;
}

.btnMinWidth {
  min-width: 150px;
}

.btnRemove {
  color: rgb(16, 7, 7);
  border: 1px solid rgba(128, 128, 128, 0.469);
  border-style: none none solid solid;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;
  box-shadow: 2px 2px 1px rgb(154, 154, 154);
}

/* .btnRemove p {
  text-shadow: 2px 2px;
} */

.alertOrganizzazione {
  position: sticky;
  top: 115px;
  z-index: 1;
}

.divPaginaTitoleFisso {
  position: sticky !important;
  left: 25% !important;
  z-index: 1;
}

.paginaTitoleFisso {
  position: sticky !important;
  left: 33% !important;
  z-index: 1;
}

.paginaTitoleOrgFisso {
  position: sticky !important;
  left: 37%;
  z-index: 1;
}

.turno {
  display: block;
}

.triggerTurno {
  background-color: #92bce1;;
  width: 100%;
  border-radius: 5px;
  color: white;
  text-align: left;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
}

.aggiungiTurno {
  background-color: #bbc7d2;;
  border-radius: 5px;
  color: white;
}

.ripetiTurno {
  background-color: #bbc7d2;;
  border-radius: 5px;
  color: white;
  margin-left: 5px;
  padding: 5px;
}

.ripetiSettimana {
  background-color: #bbc7d2;;
  border-radius: 5px;
  color: white;
  margin-left: 5px;
  padding: 5px;
  float: right;
  margin: 10px;
}

.orario {
  text-align: center;
}

.elementoTurno {
  margin: 10px;
}

.elementiTurno {
  margin: 10px;
  text-align: center;
}

.timePicker {
  display: inline;
  margin-left: 20px;
  margin-right: 20px;
}

.timePicker label {
  margin-right: 5pt;
}

.inEvidenza {
  font-weight: bold !important;
  color: #4e4eae;
  font-size: 14;
}

.actionIcon {
  cursor: pointer;
  color: #007bff;
}

.actionIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionIconContainer .actionIcon:not(:last-child) {
  margin-right: 10px;
}

.elenconopuntato {
  list-style-type: none;
}

.menubutton {
  background-color: #92bce1 !important;
}

.tabellagiornaliera {
  margin-top: 5px;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.cellaSpaziosa {
  padding: 5px;
}

.popUp {
  margin : 10px;
}

.elementoCheckbox {
  margin-left: 5px;
  margin-right: 5px;
  align-self: center;
  display: inline !important;
  width: min-content !important;
}

.isolato {
  margin : 5px;
}

.buttonPopUp {
  margin: 10px;
  text-align: center;
}

.bordiSpessi {
  border: 1px solid black !important;
}

.dimensione-content {
  width: 25% !important;
}

.lineaGrigia{
  background-color: rgb(212, 211, 211);
}

.lienaBianca{
  background-color: white;
}

#tabella_lavoratori td, 
#tabella_lavoratori th,
#tabella_sedi td,
#tabella_sedi th,
#tabella_servizi td,
#tabella_servizi th {
    border: 1px solid black;
}